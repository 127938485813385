// src/firebase.ts

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBBuVfdyTxVyh-hSxmnebGDreM3-bXoBGE",
    authDomain: "sportacs-85ed1.firebaseapp.com",
    projectId: "sportacs-85ed1",
    storageBucket: "sportacs-85ed1.appspot.com",
    messagingSenderId: "287836924501",
    appId: "1:287836924501:web:5b3f48b470f310cd5382dc",
    measurementId: "G-6B1GW6CGJK"
  };

// Initialisiere Firebase
const app = initializeApp(firebaseConfig);

// Firebase Auth und Firestore initialisieren
const auth = getAuth(app);
const firestore = getFirestore(app);

// Exporte für die Verwendung in deiner App
export { app, auth, firestore };
