import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import VerifyEmail from './auth/VerifyEmail';
import Test from './Test';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Test/>}/>
        <Route path="/verify-email" element={<VerifyEmail />} />
      </Routes>
    </Router>
  );
}

export default App;
