// src/components/VerifyEmail.tsx

import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { applyActionCode, getAuth, isSignInWithEmailLink } from 'firebase/auth';
import { auth } from '../utils/firebase'; // Firebase importieren

const VerifyEmail: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState<string>('');

  useEffect(() => {
    //const auth = getAuth();
    const oobCode = searchParams.get('oobCode');

    if (oobCode) {
      // Manuelle Bestätigung der E-Mail
      applyActionCode(auth, oobCode)
        .then(() => {
          setMessage('E-Mail-Adresse erfolgreich verifiziert!');
        })
        .catch((error) => {
          setMessage(`Fehler bei der E-Mail-Verifizierung: ${error.message}`);
        });
    } else {
      setMessage('Kein gültiger Bestätigungslink vorhanden.');
    }
  }, [searchParams]);

  return (
    <div>
      <h1>Bestätigung der E-Mail-Adresse</h1>
      <p>{message}</p>
    </div>
  );
};

export default VerifyEmail;
